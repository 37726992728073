/* HOME */
.margin-to-footer{
	visibility: hidden;
	margin: 100px;
}
.home-text{
	font-size: 1.5rem;
}
.resp-home-text{
	font-size: 1.5rem;
}
.home-main-container{
	background-image:url("../assets/about-unsplash.jpg");
	background-size: cover; 
	background-position: center; 
	background-repeat: no-repeat; 
	width: 100%;
	height: 100vh;
	background-attachment: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
}

.left-green-bod-line{
	border-left: 3px solid var(--color-palegreen);
}

.homepage-service p {
	
}
.find-out-more-btn{
	cursor: pointer;
	color: var(--color-palegreen);
}
.home-prod-con{
	color:#333
}

.home-prod-btn{
	color: #fff;
	font-weight: bold;
	background-color: var(--color-palegreen);
	padding: 10px 16px;
	cursor: pointer;
	border-radius: 5px;
	
}

.transparent-btn{
	padding: 16px 30px;
	border-radius: 36px;
	cursor:pointer;
	font-size: 20px;
	background-color: var(--color-palegreen);
	transition: background-color 0.5s, color ease-in;
}

.transparent-btn:hover{
	background-color: rgba(38, 216, 202,0.4);
	color: #000;
}

.homepage-service:hover{
	box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.home-contentsssss{
	/* background-image:linear-gradient(to bottom,rgb(172, 179, 173) , transparent 100%),url("../assets/dark-newmain.png"); */
	background-image:url("../assets/dark-newmain.png"); 
	background-size: cover; 
	background-position: center; 
	background-repeat: no-repeat; 
	width: 100%;
	height: 100vh;
	background-attachment: fixed;
	display: flex;
	justify-content: center;
	align-items: center;
}

.heading-para{
	padding:20px 10px;
	color: #fff;
	font-size:45px;
	display: flex;
	justify-content: center;
	flex-direction: column;
}
.heading-para p{
	font-size:24px;
	padding-top: 1rem;
}

.black-header{
	color: rgb(255, 255, 255);
	display: inline;
	line-height: 1.5;
	background-color: #383030;
	padding: 16px 20px;
	-webkit-box-decoration-break: clone
}

.white-header{
	display: inline;
	line-height: 1.5;
	padding: 16px 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	-webkit-box-decoration-break: clone
	
}

.home-services{
	background: rgb(255, 255, 255);
	padding: 20px;
	width: 50%;
}

.home-services{
	width: 100%;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.centered-line{
	height: 6px; 
	background-color:rgb(110, 255, 110);
	border: none;
	border-radius: 50px;
}


/* ABOUT PAGE  */

.about-main-container{
	background-image: url("../assets/about-main.jpeg");
	background-size: cover; 
	background-position: center; 
	background-repeat: no-repeat; 
	width: 100%;
	height: 100vh;
	background-attachment: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
}

.about-list {
	position: relative;
	list-style: none;
	margin-left: 0;
	padding-left: 1.2em;
}

 .about-list li:before {
	content: "+";
	position: absolute;
	left: 0;
	color: var(--color-palegreen);
	font-size: 24px;
	font-weight: 600;
}

.intro-title{
	background: rgba(12, 32, 59, 0.7);
	padding: 10px 20px;
	line-height: 1.7;
	display: inline;
	-webkit-box-decoration-break: clone;
}


.left-bor{
	border-left: var(--color-palegreen) solid 4px;
	padding-left: 10px;
}


/*** SERVICES ***/
.main-con-service{
	background-image:url("../assets/services/service-const2.jpeg");
	height:60vh;
	position: relative;
	z-index: 1;
}

.main-con-service:after{
	background: hsl(219, 30%, 18%);
  	bottom: 0;
  	content: '';
  	display: block;
  	height: 90px;
  	left: 0;
  	position: absolute;
  	right: 0;
  	transform: skewY(-2deg);
  	transform-origin: 100%;
  	z-index: -1;
}

.service-heading h1{
	color: var(--color-palegreen);
	text-align: left;
}


.sec-image{
	background-image:url("../assets/services/services-const.jpeg");
	background-size: cover; 
	background-position: center; 
	background-repeat: no-repeat; 
	width: 100%;
	height: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
}


/** PRODUCTS **/

.product-title{
	text-align: start;
	padding: 1rem 1rem;
	opacity: 0.7;
	border-left: 10px #86b7fe solid;
}

.products-main-container{
	/* background-image:url("../assets/productimages/product-main.jpg");
	background-size: cover; 
	background-position: center; 
	background-repeat: no-repeat;  */
	width: 100%;
	height: 100vh;
	/* background-attachment: fixed; */
	display: flex;
	align-items: center;
	justify-content: center;
}

.thumb-custom-dot{
	width: 10px;
	height: 10px;
	background-color: transparent; /* Customize the color of the dot */
	border-radius: 50%;
	display: inline-block;
	cursor: pointer;
}
.image-gallery-thumbnail {
	width:13px!important;
	background-color: #fff!important;
	border:none!important;
	margin:0 5px;
}

.image-gallery-thumbnail.active {
	background-color: #999!important;	
}

.img-background{
	background-color: #fff;
	padding: 16px;
}

.image-gallery-left-nav, 
.image-gallery-right-nav {
	display: none;
}

.truflo-con{
	margin:200px auto;
}

.australian-logo{
	width: 100px;
	height: 100px;
	position: absolute;
	bottom: 0px;
	right: 0px;
}

.truflo-imgs{
	position: relative;
}

/********************SERVICES*****************/

.video-con{
	background-color: hsl(218, 28%, 13%);
	display: flex;
	justify-content: center;
}

video{
	background-color: #eee;
	width:600px;	
}



.sand-blast-main{
	background-image:url("../assets/services/corrosion-protection.jpg");
	height:60vh;
	position: relative;
	z-index: 1;
	background-repeat: no-repeat;
    background-size: cover;
	width:100%;
}

.sand-blast-main:after{
	background: hsl(219, 30%, 18%);
  	bottom: 0;
  	content: '';
  	display: block;
  	height: 90px;
  	left: 0;
  	position: absolute;
  	right: 0;
  	transform: skewY(-2deg);
  	transform-origin: 100%;
  	z-index: -1;
}



.blast-paint-cont{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.blast-paint-cont h1{
	color: rgb(255, 255, 255);
	padding: 10px 20px;
	-webkit-box-decoration-break: clone;
	display: inline;
	line-height: 1.5;

}

.page-para{
	color: #fff;
	padding: 10px 20px;
	-webkit-box-decoration-break: clone;
	display: inline;
	line-height: 1.5;
}


/********* CONTACT FORM ****************/

.contact-form-main{
	margin-top: 100px;
	margin-bottom: 30px;
}

.contact-link{
	color: #fff;
	text-decoration: none;
	cursor: pointer;
}
.contact-link{
	color: #fff;
	text-decoration: none;
	cursor: pointer;
	padding: 0 10px;
	font-weight: 800;
	font-size: 14px;
}

.contact-item a:hover, .contact-item a:focus{
	color: #fff;
}
.page-contact-btn{
	background-color: var(--color-palegreen);
	padding: 5px 10px;
	cursor: pointer;
}

.contact-title{
	background: rgba(12, 32, 59, 0.7);
	padding: 20px;
	line-height: 1.5;
}


.con-info-box{
	background-color: var(--color-palegreen);
	border-radius: 15px;
}


@media screen and (max-width: 768px) {
  .intro-title{
	  line-height: 1.9;
	  font-size: 24px;
  }

  .con-info-box{
	background-color:inherit;
	border-radius: 0px;
	}
	.left-green-bod-line{
		border-left: none;
	}
 
}

@media screen and (max-width: 720px) {
	.left-green-bod-line{
		border-left: none;
	}
   
  }

@media screen and (max-width: 380px) {
  .intro-title{
	  line-height: 1.9;
	  font-size: 20px
  }
  
  .home-title-CTA{
	  display: none;
  }  
  
  .home-title-con{
	  margin-top: 150px;
  }
  
  .resp-home-text{
	  font-size: 1.3rem;
  }
  
}







