.scrol-component a {
  margin: 1rem;
  font-family: sans-serif;
  color: #fff;
  text-decoration: none;
  font-size: 1.4rem;
  transition: all 1.5s ease;
  &:hover {
	color: #013147;
  }
}

#arrow-icon,
.scroll-icon,
.scroll-icon:before {
  position: absolute;
  left: 50%;
}


.scroll-icon {
	width: 36px;
	height: 60px;
	margin-left: -18px;
	top: 90%;
	margin-top: -30px;
	border: 5px solid #fff;
	border-radius: 6px;
	transition: all 0.5s ease;
	cursor: pointer;
	@media only screen and (min-width: 768px)  {
		border: 3px solid #fff;
		border-radius: 18px;
	}

	&:before {
		content: '';
		width: 6px;
		height: 6px;
		background: #fff;
		margin-left: -3px;
		top: 8px;
		border-radius: 3px; 
		animation-duration: 1.2s;
		animation-iteration-count: infinite;
		animation-name: scroll;
		@media only screen and (min-width: 768px) {
			width: 5px;
			height: 5px;
			margin-left: -2px;
			border-radius: 2px; 
			animation-name: scroll2;
		}
	}
}

#arrow-icon {
	font-size: 30px;
	color: #fff;
	margin-left: -16px;
	top: 95%;
	margin-top: -30px;
	transition: all 0.5s ease;
	cursor: pointer;
	@media only screen and (min-width: 768px)  {
		font-size: 30px;
		color: #fff;
		margin-left: -16px;
		top: 95%;
		margin-top: -30px;
		transition: all 0.5s ease;
		cursor: pointer;
	}

}


@keyframes scroll {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		transform: translateY(24px);
	}
}

@keyframes scroll2 {
	0% {
		transform: translateY(24px);
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}