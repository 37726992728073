header {
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529; */
 
}


.nav-area {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 20px; 
  background-color: hsl(218, 28%, 13%);
}


.logo {
  text-decoration: none;
  width:60px;
}
#logo{
  width:100px;
}

.logo img{
  width:200px;
}


.desktop-nav{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}


/* menu on desktop */
.desktop-nav .menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}

.desktop-nav .menu-items {
  position: relative;
  font-size: 16px;
  font-weight: 400
}

.desktop-nav .menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  font-weight: 600
}

.desktop-nav .menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

.desktop-nav button span {
  margin-left: 3px;
}

.desktop-nav .menu-items > a,
.desktop-nav .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.desktop-nav .menu-items a:hover,
.desktop-nav .menu-items button:hover {
  background-color:var(--color-palegreen);
}

.desktop-nav .arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.desktop-nav .dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
	0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  width:15rem;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: rgba(12, 32, 59, 0.7);
  border-radius: 0.5rem;
  display: none;
}

.desktop-nav .dropdown.show {
  display: block;
}

.desktop-nav .dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.mobile-nav {
  display: none;
}

.desktop-nav{
 display: flex;
 justify-content: space-between;
 width: 100%;
}

.fixed-menu{
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

/* menu on mobile */
@media screen and (max-width: 960px) {
  .nav-area {
	justify-content: space-between;
  }

  .desktop-nav {
	display: none;
	text-align: right;
  }

  .mobile-nav {
	display: block;
  }
  .mobile-nav .mobile-nav__menu-button {
	color: inherit;
	font-size: inherit;
	border: none;
	background-color: transparent;
	cursor: pointer;
	position: relative;
  }
  .mobile-nav .menus {
	list-style: none;
	position: absolute;
	top: 50px;
	right: 20px;
	box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
	  0 4px 6px -2px rgba(71, 63, 79, 0.16);
	z-index: 9999;
	min-width: 12rem;
	padding: 0.5rem 0;
	background-color:var(--color-palegreen);
	border-radius: 0.5rem;
  }

  .mobile-nav .menu-items a {
	display: block;
	font-size: inherit;
	color: inherit;
	text-decoration: none;
  }

  .mobile-nav .menu-items button {
	display: flex;
	align-items: center;
	color: inherit;
	font-size: inherit;
	border: none;
	background-color: transparent;
	cursor: pointer;
	width: 100%;
  }

  .mobile-nav .menu-items > a,
  .mobile-nav .menu-items button {
	text-align: left;
	padding: 0.7rem 1rem;
  }

  .mobile-nav .menu-items a:hover,
  .mobile-nav .menu-items button:hover {
	background-color: rgba(12, 32, 59, 0.7);
  }

  .mobile-nav .arrow::after {
	content: "";
	display: inline-block;
	margin-left: 1.2em;
	vertical-align: 0.09em;
	border-top: 0.42em solid;
	border-right: 0.32em solid transparent;
	border-left: 0.32em solid transparent;
  }
  .mobile-nav .arrow-close::after {
	content: "";
	display: inline-block;
	margin-left: 1.2em;
	vertical-align: 0.09em;
	border-bottom: 0.42em solid;
	border-right: 0.32em solid transparent;
	border-left: 0.32em solid transparent;
  }

  .mobile-nav .dropdown {
	margin-left: 1.2em;
	font-size: 0.9rem;
	padding: 0.5rem 0;
	list-style: none;
	display: none;
  }

  .mobile-nav .dropdown.show {
	display: block;
  }
}

/* page content */
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 20px;
}

.content h1 {
  font-size: 2rem;
}

#error-page {
  /* center content on the page */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 20px;
}
